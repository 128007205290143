import {combineReducers} from 'redux';
import errorReducer from './errorReducer';
import helperReducer from './helperReducer';
import loaderReducer from './loaderReducer';
import roleReducer from './roleReducer';
import driverReducer from './driverReducer';
import successReducer from './successReducer';
import userReducer from './userReducer';
import warehouseReducer from './warehouseReducer';
import merchantReducer from './merchantReducer';
import messageReducer from './messageReducer';
import OtherReducer from './OtherReducer';
import uploadReducer from './uploadReducer';
export default combineReducers({
    users: userReducer,
    roles: roleReducer,
    message: messageReducer,
    drivers: driverReducer,
    warehouses: warehouseReducer,
    successes: successReducer,
    errors: errorReducer,
    loader: loaderReducer,
    helpers: helperReducer,
    merchants: merchantReducer,
    others: OtherReducer,
    upload: uploadReducer,
});