import React, { useEffect, useState } from 'react'
import { Form, Button, Select ,Modal, Input } from 'antd';
import { renderInput, renderSelect } from '../../../component/form/form';
import { userHelper, packageHelper } from "../../../actions/package";
import { connect } from 'react-redux';
import { updateAppUser } from "../../../actions/appUser";
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]
  

  
const EditModalForm = ({visible, setVisible, updateAppUser, refresh, data, userHelper, packageHelper }) => {
    
  const [packageData, setPackageData] = useState([]);
  const [user, setUser] = useState([]);
  const [form] = Form.useForm();
  
  const getData = async () => {
    form.setFieldsValue({
      app_id: data?.app_id,
      name: data?.name,
      package_value: data?.package_value,
      user_id: data?.user_id,
      status: data?.status,
    })
    const usr = await userHelper();
    setUser(usr);
    const pkg = await packageHelper();
    setPackageData(pkg);
  }

  const createoption = (value, label) => {
    return {value, label};
  };

  const packageOption = [
    packageData?.map(item => createoption(
      item.value,
      item.name,
    ))
  ]

  const userOption = [
    user?.map(item => createoption(
      (item.user_id).toString(),
      item.name,
    ))
  ]
  useEffect(() => {
    getData()
  }, [])

      const onFinish =async (values) => {
        // console.log('Success:', values);
        const code = await updateAppUser(values)
        if(code === 201){
          refresh()
          setVisible()
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

    return ( 
          <Modal
          title="Edit App User"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                <Form.Item
                    label="App ID"
                    name="app_id"
                    rules={[
                      { required: true, message: 'This field is required!' },
                    ]}
                    
                    >
                    <Input name="app_id" disabled/>
                </Form.Item>
                {/* {renderInput('app_id', 'App ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                } */}
                {renderInput('name', 'Location', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderSelect('package_value', 'Package', [
                    { required: true},
                  ],
                  'Select a option', packageOption[0], 'none')
                }
                {renderSelect('status', 'Status', [
                    { required: true},
                  ],
                  'Select a option', status, 'none')
                }
                
                <Form.Item {...tailLayout}> 
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              
              </Form>

        </Modal>
     );
}
 
export default connect(null,{updateAppUser, userHelper, packageHelper})(EditModalForm);
