import React from "react";
import { Form, Input, Button, Checkbox, Layout, Row, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
// import Logo from "./logo.jpeg"
import Logo from "./pi-t3.png"
import { connect } from "react-redux";
import { login } from "../../actions/auth";
const { Header, Footer, Content } = Layout;

const LoginForm = ({login}) => {
  const onFinish = (values) => {
    // console.log(values);
    // history.push('/dashboard')
    login(values)
  };
  return ( 
    <Layout style={{ minHeight: "100vh" }}>
        <Content>
            <Row style={{display: 'flex', justifyContent:'center',height: '90vh'}}>
            <Col span={12} style={{display: 'flex', justifyContent:'center'}}>
                {/* <img src={GIF} style={{width: '150px', alignSelf: 'center'}} /> */}
            </Col>
            <Col span={10} style={{alignSelf: 'center', height: '500px', backgroundColor: '#fff', padding: '20px', display: 'flex', justifyContent: 'space-around',flexDirection: 'column'}} >
                <img src={Logo} style={{width: '250px', alignSelf: 'center'}} />
                <Form
                style={{alignSelf: 'center', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                >
                    <Form.Item
                        name="user_id"
                        rules={[
                        {
                            required: true,
                            message: "User Id is required",
                        },
                        ]}
                        style={{alignSelf: 'center',width: '100%'}}
                    >
                        <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="User ID"
                        size ="large"
                        />
                    </Form.Item>
                    <Form.Item
                    
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: "Password is required",
                        },
                        ]}
                        style={{alignSelf: 'center',width: '100%'}}
                    >
                        <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        size ="large"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                        >
                        Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
            </Row>
        </Content>
        <Footer style={{ textAlign: "center", height: '10vh' }}>
          <Footer style={{ textAlign: 'center' }}>© 2024-2025 Pi-Tetris</Footer>
        </Footer>
    </Layout>
  );
};

export default connect(null,{login})(LoginForm);
