import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Breadcrumb, Select, Modal, Pagination, Tooltip, Row, Col, Popconfirm, Tag } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, SearchOutlined, StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getUsers, getUsersById, deleteUser } from "../../../actions/appUser";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import ModalForm from './modalForm';
import EditModalForm from './editModalForm';
// import '../style.css'
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;
const { Option } = Select;

const AppUserList = ({ getUsers, getUsersById, deleteUser  }) => {
  const [data, setData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [editData, setEditData] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);


  const getData = async () => {
    const res = await getUsers()
    setData(res);
  }

  useEffect(() => {
    getData()
  }, [])


  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const handleDelete = async (user) => {
    const code = await deleteUser(user);
    if(code === 201 ){
      getData();
    }
  }

  const columns = [
    {
      title: 'SL',
      dataIndex: 'sl',
      key: 'sl',
      width: '5%',
    },
    {
      title: 'App ID',
      dataIndex: 'app_id',
      key: 'app_id',
      width: '10%',
      ...getColumnSearchProps('app_id'),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      width: '20%',
      ...getColumnSearchProps('location'),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      width: '20%',
      ...getColumnSearchProps('company'),
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      width: '20%',
      ...getColumnSearchProps('branch'),
    },
    {
      title: 'Package',
      dataIndex: 'package_name',
      key: 'package_name',
      width: '10%',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
    },
    
    {
      title: 'Create at',
      dataIndex: 'create_at',
      key: 'create_at',
      width: '20%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];

  const createData = (sl ,app_id, location,company,branch, package_name, status, user_name, create_at, action) => {
    return {sl ,app_id, location, company,branch,package_name, status, user_name, create_at, action};
  };

  const rows = [
    data
      ? data?.map((item, key) => createData(
        key + 1, 
        item?.app_id, 
        item?.name, 
        item?.company_name, 
        item?.branch_name, 
        item?.package_name, 
        item.status ?
          <> 
            <Tag color="#87d068">Active</Tag> 
            </>: 
          <>
          <Tag color="#f50">Inactive</Tag>
          </>,
        item?.user_name, 
        item?.create_at,
        <>
          <Button type='primary' size="small" icon={<EditOutlined  />} onClick={() => {setEditData(item); setIsEditModalOpen(true)}}>Edit</Button> &nbsp;
          <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => handleDelete(item.app_id)}><Button type='primary' size="small" danger icon={<DeleteOutlined  />}>Delete</Button></Popconfirm>
        </>
        
         
        ))
      : "",
      
  ];

  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>TV App User</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="TV App User"
        subTitle="TV App User Management"
        extra={[
          <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { () => setIsCreateModalOpen(true) }>Create New
          </Button>,
        ]}
      >
      </PageHeader>

      <br />  
      <br />  
      <Table columns={columns} pagination={false} dataSource={rows[0]} scroll={{ x: 1000 }} sticky/>
      <br />
      
     {isCreateModalOpen ? 
      <ModalForm 
        visible={isCreateModalOpen} 
        setVisible={() => setIsCreateModalOpen(false)}
        refresh={getData}
      />
     :null}
      
     {isEditModalOpen ? 
      <EditModalForm 
        visible={isEditModalOpen} 
        setVisible={() => setIsEditModalOpen(false)}
        refresh={getData}
        data={editData}
      />
     :null}
    </Content>
   );
}
 
export default connect(null, {getUsers, getUsersById, deleteUser }) (AppUserList);