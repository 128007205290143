import { DOWNLOADED, PERCENT, TOTAL_SIZE } from "../actions/types";

const initState = {
    totalSize: null,
    downloaded: null,
    parcent: null
}
export default (state = initState , action) => {
    switch (action.type) {
 
        case TOTAL_SIZE:
            return{
                ...state, 
                totalSize: action.payload,
            };
        case DOWNLOADED:
            return{
                ...state, 
                downloaded: action.payload,
            };
        case PERCENT:
            return{
                ...state, 
                parcent: action.payload,
            };
      
        
            default:
                return state;
        }
    }