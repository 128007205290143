import axios from 'axios';

export default (token = false) => {
    
    return axios.create({
        baseURL: 'https://api.cloudad.pitetris.com/v3/',
        // baseURL: 'https://api.demo.hidayahsmart.solutions/v3/',
        headers: {
             'content-type': 'application/json',
             
            'authorization': `Bearer ${token}`
        }
    })
}