// tv-app-management-admin/ad/all
import axios from "axios";
import moment from "moment";
import history from "../history";
import api from "./apis";

import {
 
  DOWNLOADED,
  ERROR_MESSAGE,
  GET_USERS,
  LOADED,
  LOADEDING,
  PERCENT,
  SUCCESS_MESSAGE,
  TOTAL_SIZE,
  VALIDATION_ERROR
} from "./types";

export const getTvAddsAdmin = () => async (dispatch) => {
  
    dispatch({type: LOADEDING});
    try {
      const response = await api(localStorage.getItem("token")).get(
        "/tv-app-management-admin/ad/all"
      );
  
      dispatch({type: LOADED});
      return ( response.data );
      
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          
          Object.keys(err.response.data).forEach(function(key, index) {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
     
          });
          // err.response.data?.map(item => {
              // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          // })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/}
  };

  export const updateStatusAdmin = (id, status) => async (dispatch) => {

    dispatch({type: LOADEDING});
    
    const object = {
      uuid: id,
      status: status,
    };
    try {
      const response = await api(localStorage.getItem("token")).put("/tv-app-management-admin/ad/status/update", object);
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
      return response.status;
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          
          Object.keys(err.response.data).forEach(function(key, index) {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
     
          });
          // err.response.data?.map(item => {
          //     dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          // })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/}
  };