import React, { Component } from "react";
import styled from 'styled-components';
import { resetMessage } from "../actions/reset";
import { Alert,message, Progress } from 'antd';
import { connect } from "react-redux";
const Msg = styled.div`
  position: fixed;
  bottom: 1%;
  left: 1%;
  /* right: 100px;
  top:0px; */
  width: 400px;
  z-index: 10000000;
  padding: 30px 20px;
  background-color: #fdfdfd;
  color: #000000;
  border-radius: 10px;
  box-shadow: 0px 4px 13px -3px rgba(0,0,0,0.62);
`;

class DownloadAlert extends Component {
  state = {
    show: false,
    message: null,
  };
  handleShow = () => {
    this.setState({ show: !this.state.show });
  };
 
  onClose = (e) => {
    console.log(e, 'I was closed.');
  };
 
  render() {
    const {  parcent, totalSize, downloaded } = this.props;
    return (
      <Msg>
        <div style={{display: 'flex', justifyContent:'center', flexDirection: 'column'}}>
          <Progress strokeColor="#ff0707" trailColor="#4b4a4a9b" percent={parcent}  style={{color: '#FFF', alignSelf: 'center'}}/>
          <p style={{alignSelf: 'center'}}>Uploading  <strong>{downloaded}</strong> MB of <strong>{totalSize}</strong> MB</p>
        </div>
      </Msg>
    );
  }
}

export default connect(null, { resetMessage })(DownloadAlert);
