import React from 'react'
import { Form, Input } from 'antd';
const { TextArea } = Input;
const TextFieldBox = ({label,name, value, rules, size, ...rest}) => {
    return ( 
       
        <Form.Item
            label={label}
            name={name}
            rules={rules}
            
            >
            <TextArea rows={4} {...rest} size={size} />
        </Form.Item>
     );
}
 
export default TextFieldBox;