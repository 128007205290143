import React, { Component } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb,Tag, Drawer    } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, EyeOutlined, PlusCircleFilled, SearchOutlined,SettingOutlined,StopOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { getBranch,updateBranch,deleteBranch,updateBranchStatus } from "../../../actions/branch";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Remarks from '../../../component/remark';
import ModalForm from './modalForm';
import EditModalForm from './editModalForm';
import ChangePass from './changePass';


class BranchList extends Component {
    state = {
        searchText: '',
        searchedColumn: '',
        data: [],
        visible: false, 
        passVisible: false, 
        modalVisible: false, 
        editModalVisible: false, 
        placement: 'bottom',
        id: '',
      };
      componentDidMount = async () => {
        const res = await this.props.getBranch();
        this.setState({data: res})
      };
      handleStatus = async (user,active) => {

          const code = await this.props.updateBranchStatus(user,active);
          if(code === 201 ){
            const res = await this.props.getBranch();
            this.setState({data: res})
          }
        }

      getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
      });
    
      handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };

      createData = (user_id,name, company,authenticated,create_at,action) => {
        return {user_id, name,company, authenticated,create_at,action};
      };
      
      showDrawer = (id) => {
        this.setState({
          visible: true,
          id: id
        });
      };
     
      handleRefresh = async () => {
        const res = await this.props.getBranch();
        this.setState({data: res})
      }
      
      handlePassVisible = (id) => {
        this.setState({passVisible: true,
          id: id
        })
      }

      handleModalVisible = () => {
        this.setState({modalVisible: !this.state.modalVisible})
      }

      handleEditModalVisible = (user_id) => {
        this.setState({editModalVisible: !this.state.editModalVisible, id : user_id})
      }

      onClosePass = () => {
        this.setState({
          passVisible: false,
        });
      };
      
      onClose = () => {
        this.setState({
          visible: false,
        });
      };
      
      render() {
        const columns = [
          {
            title: 'ID',
            dataIndex: 'user_id',
            key: 'user_id',
            width: '10%',
            ...this.getColumnSearchProps('user_id'),
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
            ...this.getColumnSearchProps('name'),
          },
          {
            title: 'Company Name',
            dataIndex: 'company',
            key: 'company',
            width: '20%',
            ...this.getColumnSearchProps('company'),
          },
          {
            title: 'Status',
            dataIndex: 'authenticated',
            key: 'authenticated',
            width: '15%',
          },
          {
            title: 'Create At',
            dataIndex: 'create_at',
            key: 'create_at',
            width: '20%',
            ...this.getColumnSearchProps('create_at'),
          },
          {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '20%',
          },
        ];
        const {data,placement, visible,id,passVisible} = this.state
        const rows = [
          data
            ? data.map((user) => this.createData(
              user.id, 
              user.name,
              user?.company, 
             
              <>
              {user.active ?
              <> 
                <Tag color="#87d068">Active</Tag> 
                <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.handleStatus(user.uuid, false)}><Button size="small" type="danger" icon={<StopOutlined  />}>Disable</Button></Popconfirm>
              </>: 
              <>
              <Tag color="#f50">Inactive</Tag>
              <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.handleStatus(user.uuid, true)}><Button size="small" type="primary" icon={<CheckCircleOutlined  />}>Activate</Button></Popconfirm>
              </>}
              
              </>,
               user.create_at,
             
              <>
              <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => this.handleEditModalVisible(user.uuid)}>Edit</Button> &nbsp;
              <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.showDrawer(user.uuid)}><Button size="small" type="danger" icon={<DeleteOutlined  />}>Delete</Button></Popconfirm>

      
              
              </>
              ))
            : "",
        ];
        return (
          <Content style={{ margin: '0 16px' }}>


            <Breadcrumb style={{padding:"10px 0px"}}>
              <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
              <Breadcrumb.Item>Branch List</Breadcrumb.Item>
            </Breadcrumb>
            <PageHeader
              ghost={false}
              onBack={() => window.history.back()}
              title="Branch List"
              subTitle="User Management"
              extra={[
                <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { this.handleModalVisible}>Create New
                </Button>,
              ]}
            >
            </PageHeader>


            <Table columns={columns} dataSource={rows[0]} scroll={{ x: 1000 }} sticky/>
            <Remarks placement={placement} visible={visible} fnc={this.onClose} id={id} delFnc = {this.props.deleteBranch} data = {this.state.data} refresh = {this.handleRefresh} />
          
            {this.state.editModalVisible ? <EditModalForm visible={this.state.editModalVisible} setVisible={this.handleEditModalVisible} uuid={this.state.id} refresh = {this.handleRefresh} /> : null}
            <ModalForm visible={this.state.modalVisible} setVisible={this.handleModalVisible} refresh = {this.handleRefresh}/>
        </Content>
        );
      }
    }
 
export default connect(null, { getBranch,updateBranch,deleteBranch,updateBranchStatus })(BranchList);