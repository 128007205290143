import { GET_AREA, GET_CITY, GET_SUBCITY, GET_THANA, TOPBAR_STATUS } from "../actions/types";


const initState = {
    topBarStatus: false,
    city: '',
    subcity:'',
    thana: '',
    area: '',
}

export default (state = initState , action) => {
    switch (action.type) {
        case TOPBAR_STATUS:
            return{
                ...state, 
                topBarStatus: action.payload,
            };
        case GET_CITY:
            return{
                ...state, 
                city: action.payload,
            };
        case GET_SUBCITY:
            return{
                ...state, 
                subcity: action.payload,
            };
        case GET_THANA:
            return{
                ...state, 
                thana: action.payload,
            };
        case GET_AREA:
            return{
                ...state, 
                area: action.payload,
            };
        
    
        default:
            return state;
    }
}