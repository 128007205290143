// tv-app-management-admin/ad/all
import axios from "axios";
import moment from "moment";
import history from "../history";
import api from "./apis";

import {
 
  DOWNLOADED,
  ERROR_MESSAGE,
  GET_USERS,
  LOADED,
  LOADEDING,
  PERCENT,
  SUCCESS_MESSAGE,
  TOTAL_SIZE,
  VALIDATION_ERROR
} from "./types";

export const getDoctorAdmin = () => async (dispatch) => {
  
    dispatch({type: LOADEDING});
    try {
      const response = await api(localStorage.getItem("token")).get(
        "tv-app-management/doctor/admin/all"
      );
  
      dispatch({type: LOADED});
      return ( response.data );
      
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          
          Object.keys(err.response.data).forEach(function(key, index) {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
     
          });
          // err.response.data?.map(item => {
              // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          // })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/}
  };
export const getDoctorAdminById = (id) => async (dispatch) => {
  
    dispatch({type: LOADEDING});
    try {
      const response = await api(localStorage.getItem("token")).get(
        `/tv-app-management/doctor/admin/${id}`
      );
      
      dispatch({type: LOADED});
      return response.data
      // dispatch({ type: GET_USERS, payload: response.data.users });
      
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          
          Object.keys(err.response.data).forEach(function(key, index) {
            dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
     
          });
          // err.response.data?.map(item => {
              // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          // })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/}
  };
