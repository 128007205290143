import React from "react";
//import 'antd/dist/antd.css';
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Form,
  Input,
  Modal,
  PageHeader,
  Popconfirm,
  Space,
  Table,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import axios from "axios";
import { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  createBanner,
  deleteBanner,
  getBanner,
  getBannerById,
  updateBanner,
} from "../../actions/banner";
import { renderSelect } from "../../component/form/form";
import "./style.css";
// import { renderTextField } from '../../../component/form/form';
// import Remarks from '../../../component/remark';
// import ModalForm from './modalForm';
// import EditModalForm from './editModalForm';
const Search = Input.Search;

const BannerList = ({
  getBanner,
  updateBanner,
  deleteBanner,
  getBannerById,
  createBanner,
}) => {
  const [ID, setID] = useState("");
  const [data, setData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [cform] = Form.useForm();
  const [image, setImage] = useState("");

  const getData = async () => {
    const res = await getBanner();
    setData(res);
  };
  useEffect(() => {
    getData();
  }, []);

  const showModal = () => {
    setIsCreateModalOpen(true);
  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const props = {
    name: "file",
    action: "https://api.cloudad.pitetris.com/v3/banner-management/banner/upload",
    headers: {
      "content-type": "multiform/data",
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },

    onRemove: (file) => {
      const res = axios(
        "https://api.cloudad.pitetris.com/v3/banner-management/banner/delete",
        {
          headers: {
            "content-type": "multiform/data",
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: {
            file: file?.response?.name,
          },
          method: "delete",
        }
      );
    },
  };
  const handleEdit = async (item) => {
    setID(item?.id);
    const res = await getBannerById(item?.id);
    if (res) {
      setIsEditModalOpen(true);
      form.setFieldsValue({
        status: res?.status,
      });
    }
  };

  const handleDelete = async (item) => {
    const res = await deleteBanner(item?.id);
    if (res === 201) {
      getData();
    }
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getImage = async (item) => {
    const res = await getBannerById(item?.id);
    return res;
  };

  const createData = (id, name, status, action) => {
    return { id, name, status, action };
  };

  const columns = [
    {
      title: "SL",
      dataIndex: "id",
      key: "id",
      width: "10%",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "20%",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "20%",
    },
  ];

  const rows = [
    data
      ? data?.map((item, key) =>
          createData(
            key + 1,
            <img
              className="img"
              src={`https://api.cloudad.pitetris.com/static/uploads/${item?.data?.name}`}
              width="30px"
            />,

            item?.data?.status ? "Active" : "Inactive",
            <>
              <Button
                size="small"
                type="primary"
                icon={<EditOutlined />}
                onClick={() => handleEdit(item?.data)}
              >
                Edit
              </Button>{" "}
              &nbsp;
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                type="danger"
                onConfirm={() => handleDelete(item?.data)}
              >
                <Button size="small" type="danger" icon={<DeleteOutlined />}>
                  Delete
                </Button>{" "}
                &nbsp;
              </Popconfirm>
              {/* <Button size="small" type="danger" icon={<DeleteOutlined  />} onClick={() => handleDelete(item)}>Delete</Button> &nbsp; */}
            </>
          )
        )
      : "",
  ];

  const onFinish = async (values) => {
    const res = await createBanner(values, image);
    if (res === 201) {
      getData();
      setIsCreateModalOpen(false);
      cform.resetFields();
    }
  };

  const onFinishEdit = async (values) => {
    const res = await updateBanner(values, ID);
    if (res === 201) {
      getData();
      setIsEditModalOpen(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const status = [
    { value: true, label: "Active" },
    { value: false, label: "Inactive" },
  ];

  const handleImageChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
    var type = e.target.files[0].type;
    if (e.target.files && e.target.files[0]) {
      var fsize = e.target.files[0].size / 1024;
      if (
        type == "image/jpg" ||
        type == "image/jpeg" ||
        type == "image/JPG" ||
        type == "image/JPEG" ||
        type == "image/PNG" ||
        type == "image/png"
      ) {
        try {
          Resizer.imageFileResizer(
            e.target.files[0],
            600,
            400,
            "PNG",
            100,
            0,
            (uri) => {
              setImage(uri);
            },
            "blob",
            600,
            400
          );
        } catch (err) {}
      } else {
        alert("Invalid image format");
        return true;
      }
    }
  };

  return (
    <Content style={{ margin: "0 16px" }}>
      <Breadcrumb style={{ padding: "10px 0px" }}>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Banner</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Banner"
        subTitle="Banner Management"
        extra={[
          <Button
            key="1"
            type="primary"
            icon={<PlusCircleFilled />}
            onClick={showModal}
          >
            Create New
          </Button>,
        ]}
      ></PageHeader>

      <Table
        columns={columns}
        pagination={true}
        dataSource={rows[0]}
        scroll={{ x: 1000 }}
        sticky
      />
      <br />

      <Modal
        title="Add Banner"
        open={isCreateModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={cform}
        >
          <Form.Item
            label={"Upload  File"}
            name="img"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input
              type="file"
              name="img"
              onChange={(e) => handleImageChange(e)}
            />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Add Banner"
        open={isEditModalOpen}
        onOk={() => setIsEditModalOpen(false)}
        onCancel={() => setIsEditModalOpen(false)}
        width={800}
        footer={null}
        form={form}
      >
        <Form
          {...layout}
          name="Edit "
          initialValues={{ remember: true }}
          onFinish={onFinishEdit}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          {renderSelect(
            "status",
            "Status",
            [{ required: true, message: "This field is required!" }],
            "",
            status,
            "single"
          )}
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Content>
  );
};

export default connect(null, {
  getBanner,
  createBanner,
  updateBanner,
  deleteBanner,
  getBannerById,
})(BannerList);
