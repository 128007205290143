import {
    DELETE_ROLE,
    GET_ROLE,
    GET_ROLES
} from '../actions/types';

const initState = {
    allRoles: '',
    role: null,
}


export default (state=initState, action)=>{
    switch (action.type) {
        case GET_ROLES:
            return {...state, allRoles: action.payload};
            
    
        case GET_ROLE:
            return {...state, role: state.allRoles.filter(i=>i.role_name === action.payload)};
            
            
    
        case DELETE_ROLE:
            return {...state, allRoles: state.allRoles.filter(i=>i.role_name !== action.payload)};
            
    
        default:
            return state;
    }
}