import React, { useEffect } from 'react'
import { Form, Button, Select ,Modal } from 'antd';
import { renderInput, renderSelect } from '../../../component/form/form';

import { connect } from 'react-redux';
import { updatePackage } from "../../../actions/package";
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]
  

  
const EditModalForm = ({visible, setVisible, updatePackage, refresh, data}) => {
    
      const [form] = Form.useForm();
      useEffect(() => {
        form.setFieldsValue({
          name: data?.name,
          status: data?.status,
          value : data?.value,
          money : data?.money,
        });
      }, [])
      
      const onFinish = async (values) => {
        const code = await updatePackage(values, data?.uuid)
        if(code === 201){
          refresh()
          setVisible()
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
     

    return ( 
          <Modal
          title="Update Package"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
            
                {renderInput('name', 'Name', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                
                {renderInput('value', 'Value', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('money', 'Money', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderSelect('status', 'Status', [
                    { required: true},
                  ],
                  'Select a option', status, 'none')
                }
                
                <Form.Item {...tailLayout}> 
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              
              </Form>

        </Modal>
     );
}
 
export default connect(null,{updatePackage})(EditModalForm);
