import React, { Component } from 'react'
import { Form, Input, Button, Checkbox, PageHeader, Select, Breadcrumb,Modal, Divider } from 'antd';
import CommonForm, { renderDate, renderInput, renderSelect, renderTextField } from '../../../component/form/form';

import { connect } from 'react-redux';
import { getRoles } from "../../../actions/role";
import { updateUser,getUsersById } from "../../../actions/user";
import { getDepartmentHelper } from "../../../actions/department";
import { getCompanyHelper } from "../../../actions/compnay";
import { getBranchHelper } from "../../../actions/branch";
import { useEffect, useState} from 'react';
import SelectBox from '../../../component/form/select';
const { Option } = Select;
const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const gender = [
    {value: 'male', label: 'Male'},
    {value: 'female', label: 'Female'},
    {value: 'other', label: 'Other'},
  ]
  const status = [
    {value: true, label: 'Active'},
    {value: false, label: 'Inactive'},
  ]
  

  
const EditModalForm = ({visible, setVisible,user_id,getRoles,  getCompanyHelper, getDepartmentHelper, getBranchHelper, getUsersById,updateUser,refresh}) => {
      const [data, setData] = useState(null)
      const [roles, setRoles] = useState(null)
      const [depts, setDepts] = useState(null)
      const [company, setCompany] = useState(null)
      const [branch, setBranch] = useState(null)
      const [form] = Form.useForm();

      
      const createOptions = (label,value) => {
        return {label,value}
      }
      let brnOption = [];
      let myObject = {
        label: "Admin",
        value: "admin"
      };
      const branchApi = async(id) => {
        const brn = await getBranchHelper(id);
        brnOption = (
          brn ? brn.map(item => 
            createOptions(item?.name , item?.uuid))
        : null)
        brnOption.push(myObject);
        setBranch(brnOption)
      }
      useEffect(async() => { 
        const departments = await getDepartmentHelper();
        let deptOption = [];
        deptOption = (
          departments ? departments.map(item => 
            createOptions(item?.name , item?.uuid  ))
        : null)
       setDepts(deptOption)

        const comp = await getCompanyHelper();
        let compOption = [];
        compOption = (
          comp ? comp.map(item => 
            createOptions(item?.name , item?.uuid))
        : null)
        setCompany(compOption)



        const res = await getRoles();
        setRoles(res)
      
        const result = await getUsersById(user_id);
        const brn = await getBranchHelper(result?.company_id);
        brnOption = (
          brn ? brn.map(item => 
            createOptions(item?.name , item?.uuid))
        : null)
        brnOption.push(myObject);
        setBranch(brnOption)
        setData(result)
              form.setFieldsValue({
                full_name: result?.full_name,
                status: result?.status,
                email : result?.email,
                mobile_number : result?.mobile_number,
                user_id: result?.user_id,
                department: result?.department_id,
                branch: result?.branch_id,
                company: result?.company_id,
                role : result?.role_id,
                
              });
      }, [])
      
      const onFinish = async (values) => {
        console.log('Success:', values);
        const code = await updateUser(values)
        if(code === 201){
          refresh()
          setVisible()
        }
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
      let option = [];
      option = (
        roles ? roles.map(item => 
          createOptions(item.name , item.uuid ))
      : null)
    return ( 
          
          <Modal
          title="Edit User"
          centered
          visible={visible}
          onOk={() => setVisible(false)}
          onCancel={() => setVisible(false)}
          width={1000}
        >
          {data ?
              <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              style={{alignSelf: 'center'}}
              requiredMark={false}
              form={form}
              >
                <Form.Item
                  label="User ID"
                  name="user_id"
            
                >
                  <Input name="user_id" disabled/>
               </Form.Item>
                {/* {renderInput('user_id', 'User ID', [
                    { required: true, message: 'This field is required!' },
                  ])
                } */}
                {renderInput('full_name', 'Full Name', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderInput('email', 'Email', [
                    { required: true, message: 'This field is required!' },
                  ])
                }
                {renderSelect('status', 'Status', [
                    { required: true},
                  ],
                  'Select a option', status, 'none')
                }
                {renderInput('mobile_number', 'Mobile', [
                    { required: true, message: 'This field is required!' },
                    { pattern: /^(01)[0-9][0-9]{8}$/, message: 'Please valid number!' }
                  ])
                }
                {renderSelect('role', 'Role', [
                    { required: true},
                  ],
                  'Select a option', option, 'none')
                }
                {company ? 
                  <SelectBox
                    name="company"
                    label="Company"
                    rules={[{ required: true, message: "payment type is required" }]}
                    options={company}
                    placeholder="Select a option"
                    onChange={(e) => {
                      branchApi(e);
                    }}
                  />
                : null}
              
                {depts ? 
                  <SelectBox
                    name="department"
                    label="Department"
                    rules={[{ required: true, message: "payment type is required" }]}
                    options={depts}
                    placeholder="Select a option"
                   
                  />
                : null}
                
                {branch ? 
                  <SelectBox
                    name="branch"
                    label="Branch"
                    rules={[{ required: true, message: "payment type is required" }]}
                    options={branch}
                    placeholder="Select a option"
                   
                  />
                : null}
                
                <Form.Item {...tailLayout}> 
                  <Button type="primary" htmlType="submit">
                    Update
                  </Button>
                </Form.Item>
              
              </Form>
          :null}
        </Modal>
     );
}
 
export default connect(null,{getRoles,updateUser,getUsersById,  getCompanyHelper, getDepartmentHelper, getBranchHelper})(EditModalForm);
