import {
    SUCCESS_MESSAGE,
    ERROR_MESSAGE,
    AUTH_ERROR,
    AUTH_SUCCESS,
    RESET_MESSAGES,
    VALIDATION_ERROR,
    VALIDATION_AUTH_ERROR,
  } from "../actions/types";
  
  const initState = {
    success : '', 
    authSuccess : '', 
    error : '',
    authError : '',
    validationError: '',
  }

  export default (state = initState, action) => {
    switch (action.type) {
      case SUCCESS_MESSAGE:
        return {
          ...state,
          success: action.payload,
        }
      case AUTH_SUCCESS:
        return {
          ...state,
          authSuccess: action.payload,
        }
      case AUTH_ERROR:
        return {
          ...state,
          authError: action.payload,
        }
      case ERROR_MESSAGE:
        return {
          ...state,
          error: action.payload,
        }
      case VALIDATION_ERROR:
        return {
          ...state,
          validationError: action.payload,
        }
      case VALIDATION_AUTH_ERROR:
        return {
          ...state,
          authValidationError: action.payload,
        }
        case RESET_MESSAGES:
          return initState
     
      default:
        return state;
    }
  };
  