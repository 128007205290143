import axios from 'axios';
import history from '../history';
import api from './apis';

import {
    AUTH_ERROR,
    AUTH_SUCCESS,
    ERROR_MESSAGE,
    LOADED,
    LOADEDING,
    SUCCESS_MESSAGE,
    VALIDATION_AUTH_ERROR,
    VALIDATION_ERROR,
} from './types';


export const login = ({user_id, password}) => async (dispatch) => {
    
    dispatch({type: LOADEDING});
    try{
        const response =  await api(localStorage.getItem("token")).post('user-management/user/login', {
            user_id: user_id, password: password},{
                headers: { 'content-type': 'application/json'}
            });
            if(response.status === 201 ){
                localStorage.setItem("token", response.data.access_token);
                localStorage.setItem("user_id", response.data.user_id);
                localStorage.setItem("name", response.data.name);
                localStorage.setItem("role", response.data.role);
                localStorage.setItem("access", JSON.stringify(response.data.access));
                dispatch({type: LOADED})
                history.push('/dashboard')
            }
        
    }catch(err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: AUTH_ERROR, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: AUTH_ERROR, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }

}


export const logOut = () => async (dispatch) => {
    dispatch({ type: LOADEDING});
    try{
        const response = await api(localStorage.getItem("token")).post("user-management/user/logout");
        if(response.status === 201)
        {
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: AUTH_SUCCESS, payload: 'Successfully logout' });
        dispatch({ type: LOADED });
    }catch (err){
        /* =============Start========== */
          if(err?.response?.status === 422){
            err.response.data.errors.map(item => {
                dispatch({ type: VALIDATION_AUTH_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
            })
          }
          if(err?.response?.status === 401 || err?.response?.status === 403 ){
              dispatch({ type: AUTH_ERROR, payload: err.response.data.message})
              localStorage.clear();
              history.push('/login')
          }
          dispatch({ type: AUTH_ERROR, payload: err.response ? err.response.data.message : history.push('/opps') });
          dispatch({type: LOADED});
        /* ===========End============*/
      }
  }
  
  

  export const updateOwnPassword = (data, user_id) => async (dispatch) => {
    dispatch({type: LOADEDING});
  
    const object = {
      user_id: localStorage.getItem('user_id'),
      old_password:data?.old_password,
      new_password: data?.new_password,
    };
    dispatch({type: LOADED});
  
    try {
      const response = await api(localStorage.getItem("token")).put("user-management/user/self/change-password",{ ...object });
  
      if (response.status === 201) {
        
      dispatch({type: LOADED});
      dispatch({type: SUCCESS_MESSAGE,payload: "Your password is updated succesfully"});
      localStorage.setItem('token', response.data.token)
      return response.status;
      // history.push('/userList')
      }
    } catch (err) {
      /* =============Start========== */
        if(err?.response?.status === 422){
          err.response.data.errors.map(item => {
              dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
          })
        }
        if(err?.response?.status === 401 || err?.response?.status === 403 ){
            dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
            localStorage.clear();
            history.push('/login')
        }
        dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
        dispatch({type: LOADED});
      /* ===========End============*/}
  };
  