import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { getTvAdds } from "../../actions/tvadd";
import "./style.css";
const Dashboard = ({ getTvAdds }) => {
  const [data2, setData2] = useState([]);
  const [flag, setFlag] = useState(0);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  let player = null;
  const ref = (p) => {
    player = p;
  };

  const onStart = () => {
    console.log(fullscreenMode);
    if (fullscreenMode)
      findDOMNode(player)
        .requestFullscreen()
        .catch((err) => {
          console.log("Could not activate full-screen mode :(");
        });
  };

  const onEnded = () => {
    setFullscreenMode(document.fullscreenElement !== null);
  };

  useEffect(async () => {
    const res2 = await getTvAdds();
    setData2(res2);
  }, []);

  const onChange = () => {
    setFlag(flag + 1);
    if (data2?.length - 1 === flag) setFlag(0);
  };

  const data = [
    { year: "1991", value: 3 },
    { year: "1992", value: 4 },
    { year: "1993", value: 3.5 },
    { year: "1994", value: 5 },
    { year: "1995", value: 4.9 },
    { year: "1996", value: 6 },
    { year: "1997", value: 7 },
    { year: "1998", value: 9 },
    { year: "1999", value: 13 },
  ];

  const config = {
    data,
    height: 400,
    xField: "year",
    yField: "value",
    point: {
      size: 5,
      shape: "diamond",
    },
  };
  return (
    <Content style={{ margin: "0 16px" }}>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        {/* <Row gutter={8}>
                <Col span={6}>
                <Statistic title="Total Users" value={12} />
                </Col>
                <Col span={6}>
                <Statistic title="Total Advertise" value={12} />
                </Col>
                <Col span={6}>
                <Statistic title="Total App User" value={12} />
                </Col>
                <Col span={6}>
                <Statistic title="Total" value={12} />
                </Col>
                
            </Row> */}
        <br />
        <br />

        {/* <h2 style={{ textAlign: 'center' }}>Ad Preview</h2>
            {data2[flag]?.data?.convert_status === true ?
                <ReactPlayer controls width="100%" onError={onChange} onEnded={onChange} url={`https://api.cloudad.pitetris.com/static/${data2[flag]?.data?.double_status ? 'double' : data2[flag]?.data?.multi_slide_status ? 'multi' : 'single'}/imageTovideo/${data2[flag]?.data?.uuid}.mp4`} 
                    playing={true}
                />
            
            :
                <ReactPlayer width="100%" 
                    onError={onChange} 
                    onEnded={onChange} 
                    url={`https://api.cloudad.pitetris.com/static/${data2[flag]?.data?.double_status ? 'double' : data2[flag]?.data?.multi_slide_status ? 'multi' : 'single'}/video/${data2[flag]?.data?.name}`} 
                    playing={true}
                />
            } */}
        {/* <br />
            <Row gutter={8}>
                <Col span={12}>
                    <Card>
                        <Statistic
                            title="Active"
                            value={11.28}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            prefix={<ArrowUpOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Statistic
                            title="Idle"
                            value={9.3}
                            precision={2}
                            valueStyle={{ color: '#cf1322' }}
                            prefix={<ArrowDownOutlined />}
                            suffix="%"
                        />
                    </Card>
                </Col>
            </Row>
            <br />
            <Row gutter={8}>
                <Col span={12}>
                    <Card>
                        <Line {...config} />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Column
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Radar
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                
                <Col span={12}>
                    <Card>
                        <RadialBar
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <RingProgress
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                
                <Col span={12}>
                    <Card>
                        <Rose
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Funnel
                            {...config}
                            onReady={(plot) => {
                                plot.on('plot:click', (evt) => {
                                const { x, y } = evt;
                                const { xField } = plot.options;
                                const tooltipData = plot.chart.getTooltipItems({ x, y });
                                });
                            }}
                        />
                    </Card>
                </Col>
                
                
            </Row>
             */}
        <br />
      </div>
    </Content>
  );
};

export default connect(null, { getTvAdds })(Dashboard);
