import {
    GET_USERS,
    DELETE_USER,
    GET_USER
} from '../actions/types';

const initState = {
    allUsers: '',
    user: [],
}


export default (state=initState, action)=>{
    switch (action.type) {
        case GET_USERS:
            return {...state, allUsers: action.payload};
            
            case GET_USER:
                return {...state, user: state.allUsers.filter(i=>i.userId === action.payload)};
                
            case DELETE_USER:
                return {...state, allUsers: state.allUsers.filter(i=>i.userId !== action.payload)};
                
        default:
            return state;
    }
}