import React from 'react'
import { Form, Input } from 'antd';

const InputBox = ({label,name, value, rules, size, ...rest}) => {
    
    return ( 
       
        <Form.Item
            label={label}
            name={name}
            rules={rules}
            
            >
            <Input size={size ? 'middle': "middle"} name={name} value={value} {...rest}/>
        </Form.Item>
     );
}
 
export default InputBox;