import axios from "axios";
import moment from "moment";
import history from "../history";
import api from "./apis";

import {
 
  DOWNLOADED,
  ERROR_MESSAGE,
  GET_USERS,
  LOADED,
  LOADEDING,
  PERCENT,
  SUCCESS_MESSAGE,
  TOTAL_SIZE,
  VALIDATION_ERROR
} from "./types";

// ==========================Book==============
export const createTvAdd = (val, addFiles, addVideoFiles, adThumbnail, doubleType, doubleSide) => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  const data = new FormData();
  data.append("types", val.types)
  if(val.embed) data.append("embed", (val.embed).toString())
  if(val.serial) data.append("serial", val.serial)
  if(val.duration) data.append("duration", val.duration)
  data.append("status", val.status ? val.status : false)
  data.append("default", val.default ? val.default : false)

  switch (val.resolution) {
    case "general":
      data.append("height", '300')
      data.append("width", '300')
      break;
    case "HD":
      data.append("height", "720")
      data.append("width", "1280")
      break;
    case "FHD":
      data.append("height", "1080")
      data.append("width", "1920")
      break;
    case "QHD":
      data.append("height", "1440")
      data.append("width", "2560")
      break;
    case "UHD":
      data.append("height", "2160")
      data.append("width", "3840")
      break;
  
    default:
      break;
  }


  if(val.double_lr) data.append("double_lr", val.double_lr)
  if(doubleType){ data.append("double_status", true) } else {data.append("double_status", false)}
  if(doubleType) data.append("double_types", doubleType)
  if(doubleType) data.append("double_status_c", true)
  if(doubleType) data.append("double_side", doubleSide ? doubleSide : false)

  data.append("multi_slide_status", val.multi_slide_status ? val.multi_slide_status : false)
  if(val.multi_types)  data.append("multi_types", val.multi_types)
  data.append("multi_side", val.multi_side ? val.multi_side : false)
  data.append("multi_status_c", val.multi_status_c ? val.multi_status_c : false)
  
  if(val.multi_tb) data.append("multi_tb", val.multi_tb)
  if(val.start_at)  data.append("start_at", (moment(val.start_at).format('MM/DD/YYYY HH:mm:ss a')).toString())
  if(val.end_at)  data.append("end_at", (moment(val.end_at).format('MM/DD/YYYY HH:mm:ss a')).toString())
  if(addFiles) data.append("addfiles[]", addFiles);
  if(addVideoFiles) data.append("addvideofiles[]", addVideoFiles);
  if(adThumbnail) data.append("thumnil[]", adThumbnail);

  try {
    const response = await api().post("/tv-app-management/ad/create", data,{
      headers: {
        "content-type": "multipart/form-data",
        'authorization': `Bearer ${localStorage.getItem("token")}`,
      },
      onUploadProgress: (progressEvent) => {
        
        const len = progressEvent.total;
              
        let cur = 0;
        const total = (len / 1048576).toFixed(2); 

        cur += progressEvent.loaded;
        let loading = (100.0 * cur / len).toFixed(0)
        let tot = (cur / 1048576).toFixed(2)

           
        dispatch({ type: TOTAL_SIZE, payload: total});
        dispatch({ type: DOWNLOADED, payload: tot});
        dispatch({ type: PERCENT, payload: loading});
        if(loading == 100){
          
        dispatch({ type: TOTAL_SIZE, payload: null});
        dispatch({ type: DOWNLOADED, payload: null});
        dispatch({ type: PERCENT, payload: null});
      }
    }
    });
    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
    
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        //err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
       // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    }
};

export const getAllTvAdds = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api().get(
      "/tv/info/all/2"
    );
    
    dispatch({type: LOADED});
    return ( response.data );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const getTvAdds = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/tv-app-management/ad/all"
    );

    dispatch({type: LOADED});
    return ( response.data );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const getAdById = (id) => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/tv-app-management/ad/details/${id}`
    );
    
    dispatch({type: LOADED});
    return response.data
    // dispatch({ type: GET_USERS, payload: response.data.users });
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updateAd = (data,id) => async (dispatch) => {

  dispatch({type: LOADEDING});
  
  const object = {
    uuid: id,
    serial: data?.serial,
    multi_types: data?.multi_types,
    duration: data?.duration,
    status: data?.status,
    default: data?.default,
    double_status: data?.double_status,
    double_type: data?.double_type,
    double_status_c: data?.double_status_c,
    double_lr: data?.double_lr,
    double_side: data?.double_side,
    multi_types: data?.multi_types,
    multi_slide_status: data?.multi_slide_status ? data?.multi_slide_status : false,
    multi_side: data.multi_side,
    multi_status_c: data.multi_status_c,
    multi_tb: data.multi_tb,
    start_at: data.start_at ? (moment(data.start_at).format('MM/DD/YYYY HH:mm:ss a')).toString() : '',
    end_at: data.end_at ? (moment(data.end_at).format('MM/DD/YYYY HH:mm:ss a')).toString() : '',
  };
  try {
    const response = await api(localStorage.getItem("token")).put("/tv-app-management/ad/update", object);
    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
        //     dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const deleteAd = (id) => async (dispatch) => {
  dispatch({type: LOADEDING});
  
  try {
    const response = await api(localStorage.getItem("token")).delete(`/tv-app-management/ad/delete/${id}`);

    if (response.status === 201) {
      
      dispatch({type: LOADED});
      dispatch({type: SUCCESS_MESSAGE,payload: "Book is deleted succesfully"});
      
      return response.status
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const convertImage = (id) => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      `tv-app-management/ad/convert/${id}`
    );
    
    dispatch({type: LOADED});
    return response.status
    // dispatch({ type: GET_USERS, payload: response.data.users });
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updateDoubleStatus = (id, status) => async (dispatch) => {

  dispatch({type: LOADEDING});
  
  const object = {
    uuid: id,
    status: status,
  };
  try {
    const response = await api(localStorage.getItem("token")).put("/tv-app-management/ad/double/update", object);
    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
        //     dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updateStatus = (id, status) => async (dispatch) => {

  dispatch({type: LOADEDING});
  
  const object = {
    uuid: id,
    status: status,
  };
  try {
    const response = await api(localStorage.getItem("token")).put("/tv-app-management/ad/status/update", object);
    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
        //     dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const assignAd = (data,id) => async (dispatch) => {

  dispatch({type: LOADEDING});
  
  const object = {
    uuid: id,
    assign_tv: data?.assign_tv,
  };
  try {
    const response = await api(localStorage.getItem("token")).put("/tv-app-management/ad/assign", object);
    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
        //     dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const getBranchelpers = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/tv-app-management/ad/helper/branch"
    );

    dispatch({type: LOADED});
    return ( response.data );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};



export const adHelper = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem('token')).get(
      "/tv-app-management/user/helper"
    );
    
    dispatch({type: LOADED});
    return ( response.data );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const getAllDoctors = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem('token')).get(
      "/tv-app-management/doctor/all"
    );
    
    dispatch({type: LOADED});
    return ( response.data );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const createDoctor = (val, addFiles) => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  const data = new FormData();
  data.append("name", val.name)
  data.append("designation", val.designation)
  data.append("specialist", val.specialist)
  if(addFiles) data.append("addfiles[]", addFiles);

  if(val.appionment_number) data.append("appionment_number", (val.appionment_number).toString())
  if(val.previous_work_place) data.append("previous_work_place", val.previous_work_place)
  if(val.current_work_place) data.append("current_work_place", val.current_work_place)
  if(val.patient_visit_time) data.append("patient_visit_time", val.patient_visit_time)
  if(val.hospital_name) data.append("hospital_name", val.hospital_name)
  if(val.experiance) data.append("experiance", val.experiance)
  if(val.mmbs_id) data.append("mmbs_id", val.mmbs_id)
  
  try {
    const response = await api().post("/tv-app-management/doctor/create", data,{
      headers: {
        "content-type": "multipart/form-data",
        'authorization': `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
        });
        //err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
       // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    }
};

export const updateDoctor = (val, id) => async (dispatch) => { 
  dispatch({type: LOADEDING});
  const data = new FormData();
  data.append("name", val.name)
  data.append("designation", val.designation)
  data.append("specialist", val.specialist)

  if(val.appionment_number) data.append("appionment_number", (val.appionment_number).toString())
  if(val.previous_work_place) data.append("previous_work_place", val.previous_work_place)
  if(val.current_work_place) data.append("current_work_place", val.current_work_place)
  if(val.patient_visit_time) data.append("patient_visit_time", val.patient_visit_time)
  if(val.hospital_name) data.append("hospital_name", val.hospital_name)
  if(val.experiance) data.append("experiance", val.experiance)
  if(val.mmbs_id) data.append("mmbs_id", val.mmbs_id)
  
  try {
    const response = await api(localStorage.getItem("token")).put(`/tv-app-management/doctor/${id}`, val);
    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
        });
        //err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
       // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    }
};

export const getDoctorById = (id) => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/tv-app-management/doctor/${id}`
    );
    
    dispatch({type: LOADED});
    return response.data
    // dispatch({ type: GET_USERS, payload: response.data.users });
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const convertById = (id) => async (dispatch) => {
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      `/tv-app-management/doctor/convert/${id}`
    );
    
    dispatch({type: LOADED});
    return response.status
    // dispatch({ type: GET_USERS, payload: response.data.users });
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};
