import { GET_DRIVERS, GET_DRIVERS_ON_MAP, GET_SINGLE_DRIVER } from '../actions/types';

const initState = {
    allDrivers: '',
    singleDriver: '',
    driverOnMap: '',
    role: null,
}


export default (state=initState, action)=>{
    switch (action.type) {
        case GET_DRIVERS:
            return {...state, allDrivers: action.payload};
            
        case GET_SINGLE_DRIVER:
            return {...state, singleDriver: action.payload};
            
        case GET_DRIVERS_ON_MAP:
            return {...state, driverOnMap: action.payload};
            
        default:
            return state;
    }
}