import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import Spinner from "../../component/spinner";
import history from "../../history";
import Dashboard from "../dashboard/dashboard";
import RoleList from "../user/role/roleList";
import CreateRole from "../user/role/createRole";
import UserList from "../user/user/userList";
import CompnayList from "../user/compnay/CompnayList";
import EditRole from "../user/role/editRole";

import DoctorList from "../tvApp/doctor";
import AppUserList from "../tvApp/user/userList";
import TvAddList from "../tvApp/ad";
import TvAddListAdmin from "../tvApp/adAdmin";
import DoctorListAdmin from "../tvApp/doctorAdmin";
import AdDetails from "../tvApp/ad/details";
import StorageList from "../tvApp/storage";

import AppList from "../App/index";
import BannerList from "../banner";

import './index.css';
import packageList from "../tvApp/pakage/packageList";
import ImageBuild from "../tvApp/imageBuild";
import BranchList from "../user/branch/BranchList";
import DepartmentList from "../user/department/DepartmentList";
const Switcher = ({ loader }) => {
  const [access, setAccess] = useState(null);
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null
    ) {
      localStorage.clear();
      history.replace("/login");
    }
    setAccess(JSON.parse(localStorage.getItem("access")));
    // socket
  }, []);
  return (
    <div style={{ margin: '10px', backgroundColor: '#FFF', height: '100%'}}>
      {loader ? <Spinner /> : null}
      <switch>
        <Route path="/dashboard" component={Dashboard} />
        {/* <Redirect form="/" to="/dashboard" /> */}
      

        <>
          {/* ==Role== */}
          <Route path="/roles" component={RoleList} />
          <Route path="/create-role" component={CreateRole} />
          <Route path="/edit-role" component={EditRole} />

          <Route path="/app-user" component={AppUserList} />
          <Route path="/packages" component={packageList} />
          <Route path="/company" component={CompnayList} />
          <Route path="/branch" component={BranchList} />
          <Route path="/department" component={DepartmentList} />

          <Route path="/storage" component={StorageList} />
          <Route path="/add-list" component={TvAddList} />
          <Route path="/ad-details" component={AdDetails} />
          <Route path="/doctors" component={DoctorList} />
          <Route path="/doctors-admin" component={DoctorListAdmin} />
          <Route path="/image-build" component={ImageBuild} />
          <Route path="/apps" component={AppList} />
          <Route path="/add-list-admin" component={TvAddListAdmin} />

          <Route path="/banner" component={BannerList} />
          <Route path="/users" component={UserList} />
        </>
      </switch>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.loader.loading,
    success: state.message.success,
    error: state.message.error,
    validationError: state.message.validationError,
  };
};
export default connect(mapStateToProps)(Switcher);
